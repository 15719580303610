<template>
    <v-container>
      <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">Settings do seu frontoffice</p>
              <p class="page-subtitle">Imagem e vídeo</p>
            </v-col>
        </v-row>
        <v-card>
            <v-row class="pa-2 ma-2">
                <v-col cols="12" align="left">
                    <span class="text-h6">Escolha se pretende que sejam carregadas imagens ou vídeos no frontend</span>
                </v-col>
                <v-col cols="12">
                    <v-switch v-model="fields.video" label="Ativar vídeo"></v-switch>
                </v-col>
                <v-col cols="12" align="left">
                    <span class="text-h6">Imagens</span>
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image1" label="Imagem 1"></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image2" label="Imagem 2"></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input v-model="fields.image3" label="Imagem 3"></v-file-input>
                </v-col>
                <v-col cols="12" align="left">
                    <span class="text-h6">Vídeo</span>
                    <v-divider></v-divider>
                    <v-col cols="12">
                        <v-text-field v-model="fields.videoLink" label="Video"></v-text-field>
                    </v-col>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row>
                    <v-col cols="12">
                        <v-btn @click="submit">Gravar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>   
</template>

<script>
import mixin from '@/models/frontend/FrontendModel'
export default {
    name: 'Frontpage',
    mixins: [mixin],
    data(){
        return{
            fields:{
                image1: null,
                image2: null,
                image3: null,
                videoLink: null,
                video: false
            }
        }
    },
    methods:{
        submit(){
            this.save(this.fields)
            alert('Ação realizada com sucesso')
        }
    }
}
</script>
